/* Fondo general */
 .landing-page {
  /* background: linear-gradient(135deg, #f5f7fa, #c3cfe2);  Degradado elegante */
  color: #333; 
} 


/* Encabezado */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px -10px; /* Más espacio para el menú */
  background-color: rgba(255, 255, 255, 0.6); /* Transparencia */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2; /* Encima del video */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  height: 50px;
}

.menu {
  display: flex;
  gap: 20px; /* Más espacio entre los elementos del menú */
  align-items: center;
}

.menu a {
  text-decoration: none;
  color: #333;
  font-size: 18px;
  white-space: nowrap; /* Evita que las palabras se corten */
  transition: color 0.3s;
}

.menu a:hover {
  color: #000;
}

/* Botón de menú hamburguesa */
.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 28px;
  cursor: pointer;
}

/* Video */
.hero {
  position: relative;
  height: 100vh; /* Ocupa toda la pantalla */
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Asegura que el video cubra toda la pantalla */
  z-index: -1; /* Detrás de todo */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Oscurece ligeramente el video */
  z-index: 1;
}

/* Contenido sobre el video */
.hero-content {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 2;
}

.hero-content h1 {
  font-size: 4em;
  margin: 0;
}

/* Sección About Us */
.about-us {
  padding: 50px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Espacio entre los elementos */
}

.about-us h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.about-us p {
  font-size: 1.2em;
  max-width: 800px; /* Limita el ancho del texto */
  margin: 0 auto; /* Centra el texto */
  line-height: 1.6;
}

.about-us img {
  width: 45%; /* Tamaño predeterminado en pantallas grandes */
  max-width: 400px;
  height: auto;
  border-radius: 8px; /* Bordes redondeados */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Sombra suave */
}

/* Sección Collection */
.collection {
  padding: 50px 20px;
  text-align: center;
  background: linear-gradient(135deg, #f9f9f9, #ece9e6); /* Fondo suave específico para esta sección */
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Sombra para resaltar la sección */
  margin: 20px auto; /* Espaciado superior e inferior */
  max-width: 1000px; /* Centrado y ancho máximo */
}

.collection h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #555; /* Color más suave para el título */
}

.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto; /* Centrado en cualquier pantalla */
  max-width: 500px; /* Ancho máximo del carrusel */
  max-height: 800px;
}

.carousel .slide img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Estilos del Footer */
.footer {
  background: linear-gradient(135deg, #ece9e6, #f5f7fa); /* Fondo suave */
  color: #555;
  text-align: center;
  padding: 20px 10px;
  margin-top: 50px; /* Separación del resto de la página */
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.footer-links {
  display: flex;
  gap: 15px;
  margin: 15px 0;
}

.footer-links a {
  text-decoration: none;
  color: #333;
  font-size: 18px;
  transition: color 0.3s ease-in-out;
}

.footer-links a:hover {
  color: #000;
}

/* Redes sociales */
.social-icons {
  display: flex;
  gap: 15px;
}

.social-icons a {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s, transform 0.2s ease-in-out;
}

.social-icons a:hover {
  background-color: #eaeaea;
  transform: scale(1.1);
}

.social-icons img {
  width: 20px;
  height: 20px;
}

.footer-bottom {
  font-size: 14px;
  color: #888;
  margin-top: 15px;
}

/* Responsivo */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
  }
}



/* Menú responsive */
@media (max-width: 768px) {
  .menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.9); /* Fondo más visible para móviles */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px;
    z-index: 3;
  }

  .menu.open {
    display: flex;
  }

  .menu a {
    margin: 10px 0;
  }

  .menu-toggle {
    display: block;
  }

  .about-us {
    gap: 15px;
  }

  .about-us h2 {
    font-size: 2em;
  }

  .about-us p {
    font-size: 1em;
  }

  .about-us img {
    width: 90%; /* Las imágenes ocupan casi todo el ancho en móviles */
  }

  .collection h2 {
    font-size: 2em;
  }

  .carousel {
    max-width: 100%;
  }

  .carousel .slide img {
    max-height: 250px;
  }
}
